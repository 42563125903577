import React from 'react'

const ServiceController = () => {
 return (
  <div>
   <h1>Service Controller</h1>
  </div>
 )
}


export default ServiceController