import React,{Suspense,lazy} from 'react';
// import HeaderSection from './HeaderSection/HeaderSection';
import HeaderSection1 from './HeaderSection/HeaderSection1';


// const HeaderSection1=lazy(()=>import('./HeaderSection/HeaderSection1'))
const Header = () => {
 return (
  <>
   {/* <HeaderSection/> */}
   <HeaderSection1/>
   {/* <Suspense fallback={<h1>LOADING</h1>}>
    <HeaderSection1/>
   </Suspense> */}
  
   
   
  </>
  
 );
};

export default Header;