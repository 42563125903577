import React from 'react'

export default function CommonTitle({title}) {
 
 return (
  <div>
   <h3 className="comp_title">{title}</h3>  
  </div>
 )
}
