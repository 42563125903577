import React, { useContext, useEffect } from 'react';
import Food from '../FoodCard/Food/Food';
import AppNav from '../Header/AppBar/AppNav';
import Header from '../Header/Header';
import { Link as SmoothLink } from 'react-scroll';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ContactUsNew from '../ContactUs/ContactUsNew';
import Footer from '../Footer/Footer';
import MobAppNav from '../Header/AppBar/MobAppNav';
import OurService2 from '../OurService/OurService2';
import { CustomizeLoader } from '../../Utility';
import { UserContext } from '../../../App';
import loaderImg from '../../../images/logo/logo-2.png'
import './Home.css'
import LaunchDisp from '../LaunchTime/LaunchDisp';
import Partner from '../Partner/Partner';
import Event from '../EventSection/Event';
import Vlog from '../Vlogs/Vlog';


const Home = () => {
  const {itemWise}=useContext(UserContext)

  
    


 return (
   <>
   {/* {itemWise.length<1 ?
    <div id='loader_text'>
      
        <img src={loaderImg} width="20%" alt="" /> 
         
      <div>
      <h1 data-text="Loading...">Loading...</h1>
      </div>
    </div>
    :} */}
   <>
   <AppNav otherThanHome={true}/>
     <MobAppNav otherThanHome={true}/>
     
     <Header/>
     <Food/>
     <LaunchDisp/>
     <OurService2/>
     <Partner/>
     <Event/>
     <Vlog/>
     <ContactUsNew/>
     <Footer/>
    
     <SmoothLink className='link_style desk_menu' to="backtop" smooth={true} duration={800}><ArrowUpwardIcon className="back-to-top"/></SmoothLink> 
     {/* <a href="#home" className='link_style desk_menu'  smooth={true} duration={800}><ArrowUpwardIcon className="back-to-top"/></a> */}
    
     </>
   
   {/* } */}
     
  </>
 );
};

export default Home;
