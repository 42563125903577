import React from 'react'
import DashBoardTitle from '../../Dashboard/UserDashboard/DashboardComponents/DashBoardTitle'
import './partner.css'

export default function Partner() {
  return (
    <div className="container-fluid" id="partner">  
    
    
    <DashBoardTitle dashTitle={"Be A Partner"}/>
  
    <div id="partner_wrapper">
    
    
    <div className="partner_cont">
        <h1>List your restaurant or shop with Newaz Kitchen</h1>
       
        <p>
        Would you like millions of new customers to enjoy your amazing food and groceries? So would we!<br/><br/>
It's simple: we list your menu and product lists online, help you process orders, pick them up, and deliver them to hungry pandas – in a heartbeat!<br/><br/>
Interested? Let's start our partnership today!
        </p>
        <button type="button" className="btn btn-outline-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order"> Start from here</button> 
        {/* <button className="btn btn-outline-info text-white">Get Start</button> */}
    </div>
    
    </div>   

</div>
  )
}
