import React from 'react'
import './dashboard.css'
const DashBoardTitle=({dashTitle})=>{
 return (
  
    <div className="position-relative d-flex align-items-center justify-content-center responsive_dashboard px-5 py-5" id="user_dash">
        {/* <h1 className="dashboard_header display-1 text-uppercase text-white contract_stroke" >NEWAZ KITCHEN</h1> */}
        <h1 className="position-absolute text-uppercase text-primary" >{dashTitle}</h1>
    </div>
 )
        
}

export default DashBoardTitle

