import React ,{useState} from 'react';
import { Link as SmoothLink } from 'react-scroll';
import brandimg from '../../../../images/logo/logo-2.png'
import NewsLetter from '../../Footer/NewsLetter/NewsLetter';
import SocialLink from '../../Footer/socilaLink/SocialLink';
import './HeaderSection.css'

export default function HeaderSection1() {

  const [font, setFont]=useState('khaki')
  const [menuTop, setMenuTop]=useState('home')

  window.onscroll = function (e) {  
    if (window.scrollY > 100) {
      setMenuTop('menuOnTop')
    } else {
      setMenuTop('home')
    }
  }
 return (
  <div className="qt-box qt-background" id={menuTop}>
    <div className="container" id="backtop">
      <div className="row header_about">
        <div className="col-md-7 col-sm-6 mr-auto header_cont">        
        <span>WELCOME TO...</span><h2>NEWAZ KITCHEN</h2> 
        {/* <img src={brandimg} width="20%"alt="" />            */}
          {/* <h1>The finest <br/>flavors explored....</h1> */}
          <p>Take it easy this Thanksgiving with our Cooking! It has everything you need to whip up a feast, right in your own dining!</p>  
          <div className='header-btn pt-3' >    
          <button type="button" className=" btn btn-outline-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order">  <SmoothLink  to="order" smooth={true} duration={800}>Let's Order</SmoothLink></button> 
          <button type="button" className="btn btn-outline-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order">  <SmoothLink  to="event" smooth={true} duration={800}>Set Event</SmoothLink></button> 
          </div>  
          <p className="py-5 order_call">Call <span ><a onMouseEnter={()=>setFont('crimson')} onMouseLeave={()=>setFont('khaki')} onMouseMove={()=>setFont('crimson')} style={{color:font, textDecoration: 'none', border: 'none'}} href="tel:*********">+987654321</a>  </span> for your order Details</p>
          <SocialLink/>
        </div>   

       
            
      </div>
      
    </div>
    {/* <SocialLink/> */}
    {/* <img className='wave-for-img' src="https://raw.githubusercontent.com/JaznanOfficial/e-learning/main/wave1.png" alt="" /> */}
    
</div>
 )
}
