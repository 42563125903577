import React from 'react'
import { Link } from 'react-router-dom';
import './socialLink.css'
export default function socialLink() {
 return (

  <div className="socialLink">
   
 
  <div class="frame">
         {/* <!-- add social icons--> */}
         <a href="https://www.facebook.com/nnnewaz/" target="_blank" className="btn">
             <i class="fa fa-facebook" style={{color: "#3b5998"}}></i>
         </a>
         {/* <!-- twitter--> */}
         <a href="https://twitter.com/nazifa_tamanna" target="_blank" className="btn">
            <i class="fa fa-twitter" style={{color:"#1DA1F2"}}></i>
        </a>
        {/* <!-- dribbble--> */}
        <a href="https://www.instagram.com/nazifa_shuchi"  target='_blank' rel="noopener noreferrer" className="btn">
            <i class="fa fa-instagram" style={{color:"#ea4c89"}}></i>
        </a>
        {/* <!--linkedin--> */}
        <a href="https://www.linkedin.com/company/newaz-kitchen/" target="_blank" className="btn">
            <i class="fa fa-linkedin" style={{color:"#0e76a8"}}></i>
        </a>
        {/* <!--pocket--> */}
        <a href="https://www.pinterest.com/nazifatamanna" target="_blank" className="btn">
            <i class="fa fa-pinterest" style={{color:"#ee4056"}}></i>
        </a>
        {/* <!-- envelope--> */}
        <a href="https://www.youtube.com/channel/UCTddhjQx5VxjNRp43zA0h6g" target="_blank" className="btn">
            <i class="fa fa-youtube" style={{color:"#FF0000"}}></i>
        </a>
     </div>
   </div>  
 )
}
