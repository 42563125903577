import React from 'react';
import eventImg from '../../../images/section-wise-img/event-img/event.png'
import DashBoardTitle from '../../Dashboard/UserDashboard/DashboardComponents/DashBoardTitle';
import { useHistory } from 'react-router-dom';
import './event.css'

export default function Event() {

const history = useHistory();
const eventHandle=()=>{
    history.push('/dashboard-myevents')
}

  return (
    
    <div className="container-fluid" >  
    
    
    <DashBoardTitle dashTitle={"Event Management"}/>
    <div className="container" id="event">
      <div className="row ">
        <div className="col-md-6 col-sm-6 mx-auto ">        
                    
          <h1>We are here <br/>To handle your Event...</h1>
          <p>Take it easy this Thanksgiving with our Cooking! It has everything you need to whip up a feast, right in your own dining!</p>  
                
          <button onClick={eventHandle} type="button" className="btn btn-outline-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order">  GET STARTED</button>     

          <p className="py-5 order_call">Call <span ><a onMouseEnter={""} onMouseLeave={""} onMouseMove={""}  href="tel:*********">+987654321</a>  </span> for your order Details</p>
          
        </div>   
        <div className="col-md-6 col-sm-10 mr-auto ">  
        <img src={eventImg} alt="" class="img-fluid" />
        
        </div>
        
            
      </div>
    </div>
    </div>
  )
}
