import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import { Fab, Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(2),
  },
  media: {
    height: 190,
  },
}));

function Media(props) {
  const { loading = false } = props;
  const classes = useStyles();

  return (
    
    <Card className={classes.card}>
      <CardHeader
        avatar={
          
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          
            
          
        }
        action={
          
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          
        }
        title={
          
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
           
        }
        subheader={loading ? <Skeleton animation="wave" height={10} width="40%" /> : '5 hours ago'}
      />
      
        <Skeleton animation="wave" variant="rect" className={classes.media} />
      
        

     
    </Card>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function SkeletonLoader() {
  return (
    <Grid  item={true} xs={10}  sm={5} md={4} lg={3} >
      
        <Media loading />
     
      
    </Grid>
  );
}
