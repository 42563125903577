export class AdminForm{
 constructor(id,label,variant,name){
   this.id=id;
   this.label=label;
   this.variant=variant;
   this.name=name
 }
}

export class UserForm{
  constructor(id,label,variant,name){
    this.id=id;
    this.label=label;
    this.variant=variant;
    this.name=name
  }
}