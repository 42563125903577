import React from 'react'
import DashBoardTitle from '../../Dashboard/UserDashboard/DashboardComponents/DashBoardTitle'
import './vlog.css';
export default function Vlog() {


const cookingData=["https://www.youtube.com/embed/p5IleDSzPyA","https://www.youtube.com/embed/mzChCaPoxnI","https://www.youtube.com/embed/dGiugBGnWs8","https://www.youtube.com/embed/M7oFTooaX6g","https://www.youtube.com/embed/XxdpHSV972g","https://www.youtube.com/embed/7QBjCAjsoog"]




  return (
    <div className="container-fluid" id="vlog">  
    
    
    <DashBoardTitle dashTitle={"FOOD PROCESS AT A GLANCE"}/>
    <div className="container" >
      <div className="row ">
        <div className="col-md-12 col-sm-12 mx-auto ">        
                    
          {/* <h1>We are here <br/>To handle your Event...</h1>
          <p>Take it easy this Thanksgiving with our Cooking! It has everything you need to whip up a feast, right in your own dining!everything you need to whip up a feast, right in your own </p>   */}
          {/* width="560" height="315" */}
          <div className="row">
          {cookingData.map((item)=>
            <div className="col-md-4 col-sm-6 mx-auto p-2"  > 
            <iframe  key={Math.random()} src={item} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          )}
          </div>  
          <a href="https://www.youtube.com/channel/UCTddhjQx5VxjNRp43zA0h6g" target='_blank'><button type="button" className=" btn btn-outline-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order">Visit our Channel</button></a>     
          {/* <button onClick={eventHandle} type="button" className="btn btn-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order">  GET STARTED</button>      */}

          {/* <p className="py-5 order_call">Call <span ><a onMouseEnter={""} onMouseLeave={""} onMouseMove={""}  href="tel:*********">+987654321</a>  </span> for your order Details</p> */}
          
        </div>   
        <div className="col-md-6 col-sm-10 mr-auto ">  
        {/* <img src={eventImg} alt="" class="img-fluid" /> */}
        
        </div>
        
            
      </div>
    </div>
    </div>
  )
}
