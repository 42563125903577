import React from 'react';
import DashBoardTitle from '../../Dashboard/UserDashboard/DashboardComponents/DashBoardTitle';
import './launch.css'

export default function LaunchDisp() {
  return (
    
    <div className="container-fluid" id="lunch">  
    
        
        <DashBoardTitle dashTitle={"Order your lunch"}/>
      
        <div className="launchDisp_wrapper">
        
        
        <div className='launch_cont'>
            <h1>Take your office out to lunch</h1>
            <h4>Newaz Kitchen for business</h4>
            <p>
            Order lunch or fuel for work-from-home, late nights in the office, corporate events, client meetings, and much more.
            </p>
            <button type="button" className="btn btn-outline-info text-white" data-toggle="tooltip" data-placement="bottom" title="Click for order"> Get Started</button>
        </div>
        
        </div>   
   
    </div>
 
  )
}
